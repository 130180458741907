.influencerDashboard {
  .makeStyles-pageHeader-44 {
    margin: 0;
  }
  .creator {
    border-bottom: 2px solid lighten(#000, 20);
    padding: 20px 0px;

    .top {
      display: flex;
      align-items: center;

      span {
        margin-left: 1rem;
        background-color: #26348b;
        padding: 3px 10px;
        font-size: 0.8rem;
        color: #fff;
        border-radius: 100px;
      }
    }

    button {
      margin-top: 1rem;
      background-color: #00ffbf;
      color: #000;
      padding: 10px 20px;
      font-size: 1rem;

      a {
        color: #000;
      }
    }
  }
}
