//Import Custom Scroll styles

// Variables
$primaryColor: #00ffbf;
$swipe-icon-size: 60px;
$swipe-icon-size-large: 134px;
$animation-speed: 1.25s;

$global-margin: 16px;

// Layout
html,
body,
.md-full-height {
  height: 100%;
}
.md-full-height-container {
  min-height: 100%;
}

a:focus,
[tabindex]:focus {
  outline: 0;
}

a {
  color: $primaryColor;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

img {
  max-width: 100%;
}

p {
  margin-top: 0;
  margin-bottom: 0.75em;
}

ul.content-list,
ol.content-list {
  padding-left: 20px;
  margin: 0 0 $global-margin;
  & > li {
    margin-bottom: 5px;
  }
}
sup,
sub {
  font-size: 0.6em;
}

.overflow-hidden {
  overflow: hidden;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.ucfirst {
  text-transform: capitalize;
}
// Animations swipe homepage
@keyframes slideSwipeIconLeft {
  from {
    left: 50%;
    opacity: 0.5;
    width: $swipe-icon-size;
    height: $swipe-icon-size;
  }
  50% {
    left: 20%;
    opacity: 1;
    width: $swipe-icon-size-large;
    height: $swipe-icon-size-large;
  }
  70% {
    left: 20%;
    opacity: 0;
  }
  100% {
    left: -10%;
    opacity: 0;
  }
}
.animate-swipe-icon-left {
  animation-name: slideSwipeIconLeft;
  animation-duration: $animation-speed;
  animation-fill-mode: both;
}

@keyframes slideSwipeIconRight {
  from {
    left: 50%;
    opacity: 0.5;
    width: $swipe-icon-size;
    height: $swipe-icon-size;
  }
  50% {
    left: 80%;
    opacity: 1;
    width: $swipe-icon-size-large;
    height: $swipe-icon-size-large;
  }
  70% {
    left: 80%;
    opacity: 0;
  }
  100% {
    left: 110%;
    opacity: 0;
  }
}
.animate-swipe-icon-right {
  animation-name: slideSwipeIconRight;
  animation-duration: $animation-speed;
  animation-fill-mode: both;
}

@keyframes slideSwipeIconUp {
  from {
    top: 50%;
    opacity: 0.5;
    width: $swipe-icon-size;
    height: $swipe-icon-size;
  }
  50% {
    top: 20%;
    opacity: 1;
    width: $swipe-icon-size-large;
    height: $swipe-icon-size-large;
  }
  70% {
    top: 20%;
    opacity: 0;
  }
  100% {
    top: -10%;
    opacity: 0;
  }
}

.animate-swipe-icon-up {
  animation-name: slideSwipeIconUp;
  animation-duration: $animation-speed;
  animation-fill-mode: both;
}
