.CreatorProfile {
  h5 {
    opacity: .3;
    margin:0;
    padding:0;
  }

  h2 {
    width: 100%;
  }

  .creator {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .info {
    margin-bottom:2rem;
    width:30%;

    @media screen and (max-width: 1400px) {
      width:45%;
    }

    @media screen and (max-width: 1024px) {
      width:100%;
    }
  }
}
