.influencerDashboard {
  .link,
  .people {
    padding: 20px 15px;
    width: 100%;

    input {
      padding: 10px 20px;
      width: 100%;
      max-width: 500px;
      border: 1px solid #ececec;
      background-color: #ececec;
      border-radius: 5px;
      margin-top: 10px;
    }
  }

  .people {
    margin-top: 3rem;

    > p {
      margin-bottom: 1rem;
    }

    .table {
      .row {
        display: flex;
        padding:10px 15px;
        justify-content: space-between;

        &.header {
            background-color: #ececec;

            .item {
                color:#000;
            }
        }

        .item {
          width: 30%;
        }
      }
    }

    .invitee {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 15px;
      border-radius: 10px;

      color: #000;
    }
  }
}
