.influencerAdminDashboard {
  padding-bottom: 40px;
  .link,
  .people {
    padding: 20px 15px;
    width: 100%;
  }

  svg {
    margin-top: -15px;
  }

  .MuiFilledInput-input {
    padding: 15px 15px 15px 10px;
    align-items: center;
  }

  .add {
    position: relative;
    margin-top: 0 !important;

    .code {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;

      button {
        width: 200px;
        margin-top: 2rem;
      }
    }

    .searchGroup {
      max-width: 500px;

      &:hover {
        .search__results {
          display: block;
        }
      }
      .search__results {
        display: none;
      }
    }
    .search__results {
      position: absolute;
      background-color: #fff;
      width: 600px;
      border-radius: 0px 0px 5px 5px;
      top: 144px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.45);
      z-index: 60;
      .item {
        display: flex;
        padding: 15px;
        justify-content: space-between;
        color: #000;
        border-bottom: 2px solid #ececec;
        cursor: pointer;

        &:hover {
          background-color: #1c1c1c;
          color: #fff;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .people {
    margin-top: 3rem;

    > p {
      margin-bottom: 1rem;
    }

    .table {
      .row {
        display: flex;
        padding: 10px 15px;
        justify-content: space-between;
        background-color: black;
        font-size: 16px;

        &:nth-child(odd) {
          background-color: #2b2b2b;
        }

        &.header {
          background-color: #ececec;
          background: #2b2b2b;
          font-size: 18px;

          .item {
            color: white;
            line-height: 24px;
            padding: 10px;
          }
        }

        .item {
          width: 22%;
        }
      }
    }

    .invitee {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 15px;
      border-radius: 10px;

      color: #000;
    }
  }
}
